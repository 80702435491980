import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import PostLink from "../components/post-link"
import SEO from "../components/seo"
import BlogNavigation from "../components/blog-navigation"
import Container from "../components/container"
import PostListContainer from "../components/post-list-container"

const BlogList = ({
  data: {
    allMarkdownRemark: { edges },
  },
  pageContext,
}) => {
  const renderPosts = edges =>
    edges.map(edge => <PostLink key={edge.node.id} post={edge.node} />)

  const { currentPage, numPages } = pageContext

  return (
    <Layout>
      <SEO title="Blog" path="/blog/" />
      <Container>
        <PostListContainer className="content">
          {renderPosts(edges)}
        </PostListContainer>
        <BlogNavigation currentPage={currentPage} numPages={numPages} />
      </Container>
    </Layout>
  )
}

export default BlogList

export const pageQuery = graphql`
  query($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            url
            title
            featured_image {
              childImageSharp {
                fluid(maxWidth: 600, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
