import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { cx } from "emotion"
import theme from "styled-theming"

const buttonTextColor = theme("mode", {
  light: props => props.theme.colors.purple,
  dark: props => props.theme.colors.darkestPurple,
})

const buttonTextHoverColor = theme("mode", {
  light: props => props.theme.colors.white,
  dark: props => props.theme.colors.white,
})

const buttonBackgroundColor = theme("mode", {
  light: props => props.theme.colors.white,
  dark: props => props.theme.colors.white,
})

const buttonBackgroundHoverColor = theme("mode", {
  light: props => props.theme.colors.purple,
  dark: props => props.theme.colors.lightPurple,
})

const StyledButton = styled.button`
  text-transform: uppercase;
  font-size: ${props => props.theme.typography.scale[0]};
  font-weight: ${props => props.theme.typography.weights.bold};
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  color: ${buttonTextColor};
  background-color: ${buttonBackgroundColor};
  background-image: none;
  background-size: unset;
  border: 2px solid ${buttonTextColor};
  display: inline-block;
  padding: ${props => `${props.theme.spacers.xs} ${props.theme.spacers.sm}`};

  &:visited {
    color: ${buttonTextColor};
  }

  &:hover,
  &:active {
    background-color: ${buttonBackgroundHoverColor};
    background-image: none;
    background-size: unset;
    color: ${buttonTextHoverColor};
  }
`

const StyledButtonAsLink = styled(StyledButton)`
  text-decoration: none;

  &:hover,
  &:active {
    filter: none;
  }
`

const Button = ({ className, to, rel, children }) => {
  const buttonClass = cx(className, "button")

  if (to) {
    // Link styled as a button
    return (
      <StyledButtonAsLink as={Link} className={buttonClass} to={to} rel={rel}>
        {children}
      </StyledButtonAsLink>
    )
  }

  return <StyledButton className={buttonClass}>{children}</StyledButton>
}

Button.propTypes = {
  className: PropTypes.string,
  rel: PropTypes.string,
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default Button
