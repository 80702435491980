import React from "react"
import Button from "./button"
import styled from "@emotion/styled"

const StyledNavigation = styled.div`
  margin-top: ${props => props.theme.spacers.lg};

  .button {
    margin-right: ${props => props.theme.spacers.sm};

    &:last-of-type {
      margin-right: 0;
    }
  }
`

const BlogNavigation = ({ currentPage, numPages }) => {
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? "/" : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()

  return (
    <StyledNavigation>
      {!isFirst && (
        <Button to={`/blog/${prevPage}`} rel="prev">
          ← Previous Page
        </Button>
      )}
      {!isLast && (
        <Button to={`/blog/${nextPage}`} rel="next">
          Next Page →
        </Button>
      )}
    </StyledNavigation>
  )
}

export default BlogNavigation
